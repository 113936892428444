<template>
  <div style="background-color: #ffffff;padding: 20px;border-radius: 5px;">
    <div class="df-jc-s-b">
      <el-button-group>
        <el-button
          v-for="v of CollectionStatus"
          :key="v.id"
          size="mini"
          :to="v.to"
          tag="el-button"
          @click.native="selectTab(v)"
          :class="selectTabID==v.id?'TabStyle':''"
        >{{v.text}}</el-button>
      </el-button-group>
    </div>
    <keep-alive v-for="(item,index) in CollectionStatus" :key="index">
      <component :is="item.compName" v-show="item.id==selectTabID" />
    </keep-alive>
  </div>
</template>

<script>
export default {
  name: "Collection",
  components: {
    CollectionGoods: () =>
      import("@/views/Account/app/CollectionFile/CollectionGoods.vue"),
    CollectionShop: () =>
      import("@/views/Account/app/CollectionFile/CollectionShop.vue"),
    CollectionPersonalCard: () =>
      import("@/views/Account/app/CollectionFile/CollectionPersonalCard.vue"),
    CollectionWorkerCard: () =>
      import("@/views/Account/app/CollectionFile/CollectionWorkerCard.vue"),
    CollectionEnterpriseCard: () =>
      import("@/views/Account/app/CollectionFile/CollectionEnterpriseCard.vue")
  },
  data() {
    return {
      selectTabID: null,
      TabcompName: null,

      // Tab栏
      CollectionStatus: [
        { compName: "CollectionGoods", text: "收藏的商品", id: 1 },
        { compName: "CollectionShop", text: "收藏的店铺", id: 2 },
        {
          compName: "CollectionPersonalCard",
          text: "收藏的个人名片",
          id: 3
        },
        {
          compName: "CollectionWorkerCard",
          text: "收藏的员工名片",
          id: 4
        },
        {
          compName: "CollectionEnterpriseCard",
          text: "收藏的企业名片",
          id: 5
        }
      ]
    };
  },
  methods: {
    // Tab栏选中
    selectTab(v) {
      this.selectTabID = v.id;
      console.log('Tab栏选中',v.text);
      this.TabcompName = v.compName;
    }
  }
};
</script>

<style lang="less" scoped>
.TabStyle {
  text-decoration: none;
  color: white;
  background-color: #409eff;
}

</style>